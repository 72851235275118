import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import {
    Archive,
    BorderColorOutlined,
    Delete,
    FileUploadOutlined,
    Send,
    VisibilityOutlined,
} from "@mui/icons-material";
import { useAppContext } from "../../../../../context/AppContext";

function ListActives(props) {
    const {permissions} = useAppContext(); 

    const {
        rowsActives,
        documentType,
        idUserLogged,
        colorCell,
        handleOpenModalConfirm,
        handleOpenModalUploadUpdate
    } = props


    return (
        <TableBody
            sx={{ display: documentType !== 1 ? "none" : "" }}
        >
            {rowsActives.map((rowsActives) => (
                <TableRow
                    hover
                    key={rowsActives.id_petar}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }} align="center">
                        {rowsActives.name}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }} align="center">
                        {rowsActives.document_url === null
                            ? "SISTEMA"
                            : "ARCHIVO"}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }} align="center">
                        {rowsActives.status}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }} align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {rowsActives.id_petar_statuses == 3 ? (
                                rowsActives.document_url !== null ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETAR_ACTI_VER}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={rowsActives.document_url}
                                        directionTarget={"_blank"}
                                    />
                                ) : (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETAR_ACTI_VER}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={true}
                                        directionUrl={"/editPetar?id_petar=" + rowsActives.id_petar + "&readOnly=" + true}
                                    />
                                )
                            ) : ""}

                            {
                                rowsActives.id_petar_statuses === 2 && (
                                    <ButtonIconControla
                                        roles={
                                            (rowsActives.responsible && rowsActives.responsible.some((user) => user.id_users === idUserLogged)) ||
                                                (rowsActives.authorizations && rowsActives.authorizations.some((user) => user.id_users === idUserLogged))
                                                ? []
                                                : permissions.DOC_HG_PROC_PETAR_MOD_AGREGAR_MODELO
                                        }
                                        icon={<VisibilityOutlined sx={{ color: 'white' }} />}
                                        backgroundColor={'#305AD9'}
                                        backgroundColorHover={'#0E4AFF'}
                                        textTooltip={'Ver'}
                                        isNavegationOrigin={true}
                                        directionUrl={'/editPetar?id_petar=' + rowsActives.id_petar + '&readOnly=' + true}
                                    />
                                )
                            }
                            {/* {rowsActives.document_url !== null ? (
                                <ButtonIconControla
                                  roles = {permissions.DOC_HG_PROC_PETAR_ACTI_VER}
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  directionUrl={rowsActives.document_url}
                                  directionTarget={"_blank"}
                                />
                              ) : (
                                <ButtonIconControla
                                  roles = {permissions.DOC_HG_PROC_PETAR_ACTI_VER}
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  functionOnClick={() =>
                                    (window.location.href =
                                      "/editPetar?id_petar=" +
                                      rowsActives.id_petar)
                                  }
                                />
                              )} */}

                            {rowsActives.document_url === null &&
                                rowsActives.id_petar_statuses === 1 ? (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETAR_ACTI_EDITAR}
                                        icon={
                                            <BorderColorOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#2D9CDB"}
                                        backgroundColorHover={"#33AEF4"}
                                        textTooltip={"Editar"}
                                        isNavegationOrigin={true}
                                        directionUrl={"/editPetar?id_petar=" + rowsActives.id_petar}
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETAR_ACTI_ENVIAR_A_TRABAJADORES}
                                        icon={<Send sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Enviar a trabajadores"}
                                        // functionOnClick={() =>
                                        //   sendToWorkers(rowsActives.id_petar)
                                        // }
                                        functionOnClick={() => {
                                            // const data = {
                                            //   message: "Tienes un nuevo documento por revisar 2",
                                            //   document_url: "/editPetar?id_petar=" + rowsActives.id_petar,
                                            //   id_users_created: rowsActives.id_users_created,
                                            //   id_users_modified: rowsActives.id_users_modified,                   
                                            //   id_users_sender: getUser().id_users,                                       
                                            //   id_users_receiving: []
                                            // }

                                            handleOpenModalConfirm(
                                                rowsActives.id_petar,
                                                "¿Estás seguro de enviar a los trabajadores este documento?",
                                                "",
                                                "Sí, enviar",
                                                "sendWorkers"
                                                // data
                                            )
                                        }
                                        }
                                    />
                                </>
                            ) : rowsActives.document_url !== null &&
                                rowsActives.id_petar_statuses === 1 ? (
                                <>
                                    <ButtonIconControla
                                        icon={
                                            <BorderColorOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        roles={permissions.DOC_HG_PROC_PETAR_ACTI_EDITAR}
                                        backgroundColor={"#2D9CDB"}
                                        backgroundColorHover={"#33AEF4"}
                                        textTooltip={"Editar"}
                                        functionOnClick={() =>
                                            handleOpenModalUploadUpdate(
                                                rowsActives.id_petar,
                                                rowsActives.name,
                                                rowsActives.code
                                            )
                                        }
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETAR_ACTI_PUBLICAR}
                                        icon={
                                            <FileUploadOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Publicar"}
                                        functionOnClick={() =>
                                            handleOpenModalConfirm(
                                                rowsActives.id_petar,
                                                "¿Estás seguro de publicar este documento?",
                                                "",
                                                "Sí, publicar",
                                                "public"
                                            )
                                        }
                                    />
                                </>
                            ) : (
                                <></>
                            )}

                            {
                                rowsActives.id_petar_statuses === 2 && (
                                    <>
                                        {rowsActives.responsible && rowsActives.responsible.some((user) => user.id_users === idUserLogged) ||
                                            (rowsActives.authorizations && rowsActives.authorizations.some((user) => user.id_users === idUserLogged)) ? (
                                            <>
                                                <ButtonIconControla
                                                    icon={<BorderColorOutlined sx={{ color: 'white' }} />}
                                                    backgroundColor={'#2D9CDB'}
                                                    backgroundColorHover={'#33AEF4'}
                                                    textTooltip={'Editar'}
                                                    isNavegationOrigin={true}
                                                    directionUrl={'/editPetar?id_petar=' + rowsActives.id_petar}
                                                />
                                            </>
                                        ) : null}

                                        <ButtonIconControla
                                            roles={permissions.DOC_HG_PROC_PETAR_ACTI_PUBLICAR}
                                            icon={<FileUploadOutlined sx={{ color: 'white' }} />}
                                            backgroundColor={'#169073'}
                                            backgroundColorHover={'#1AA483'}
                                            textTooltip={'Publicar x'}
                                            functionOnClick={() =>
                                                handleOpenModalConfirm(
                                                    rowsActives.id_petar,
                                                    '¿Estás seguro de publicar este documento?',
                                                    '',
                                                    'Sí, publicar',
                                                    'public'
                                                )
                                            }
                                        />
                                    </>
                                )
                            }

                            {rowsActives.id_petar_statuses === 3 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_HG_PROC_PETAR_ACTI_ARCHIVAR}
                                    icon={<Archive sx={{ color: "white" }} />}
                                    backgroundColor={"#F2994A"}
                                    backgroundColorHover={"#FF881E"}
                                    textTooltip={"Archivar"}
                                    functionOnClick={() =>
                                        handleOpenModalConfirm(
                                            rowsActives.id_petar,
                                            "¿Estás seguro de archivar el documento?",
                                            "",
                                            "Sí, archivar",
                                            "archived"
                                        )
                                    }
                                />
                            ) : (
                                <></>
                            )}
                            {rowsActives.id_petar_statuses === 1 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_HG_PROC_PETAR_ACTI_ELIMINAR}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() =>
                                        handleOpenModalConfirm(
                                            rowsActives.id_petar,
                                            "¿Estás seguro de eliminar este documento?",
                                            'Una vez eliminado no podrá editar nuevamente el PETAR:  "' +
                                            rowsActives.name +
                                            '"',
                                            "Si, eliminar",
                                            "delete"
                                        )
                                    }
                                />
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListActives;