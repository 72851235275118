import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import {
  Add,
  FileDownload,
  UploadFile,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import MyUsers from "../../api/MyUsers";
import BannerButtonControla from "../../components/cards/bannerButtonControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import LoadingControla from "../../components/load/loadingControla";
import { useAppContext } from "../../context/AppContext";
import TableControla from "../../components/tables/table-controla";
import ListUsersActives from "./components/list-usersActives";
import ListUsersInactives from "./components/list-usersInactives";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const grey = "#9191B0";
const purple = "#8B8BD8";

function Index() {
  const { logoCompany, permissions } = useAppContext();
  const rolesButtonCreateUser = permissions.CONF_USER_CREAR;
  const rolesButtonImportUser = permissions.CONF_USER_IMPORT;

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [userId, setUserId] = useState("");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  const [usersActivesRows, setUsersActivesRows] = useState([]);
  const [usersInactivesRows, setUsersInactivesRows] = useState([]);
  const [rowsType, setrowsType] = useState(0);
  const [visibilityPassword, setVisibilityPassword] = useState(false);

  const togglePassword = () => {
    setVisibilityPassword(prev => !prev)
  }

  const handleChangerowsType = (event, newValue) => {
    setrowsType(newValue);
  };

  const handleGetUsers = async () => {
    handleOpenLoading();
    const response = await MyUsers.users();

    if (response.success === false) {

    } else {
      const usersActives = response.users_actives;
      const usersInactives = response.users_inactives;

      setUsersActivesRows(usersActives);
      setUsersInactivesRows(usersInactives);
    }

    setOpenLoading(false);
    return response;
  };

  

  // cargar usuarios con archivos masivos
  const [openModalLoadUserFile, setOpenModalLoadUserFile] = useState(false);
  const handleOpenModalUpload = () => {
    setOpenModalLoadUserFile(true);
  };

  const [loadUserFile, setLoadUserFile] = useState();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setLoadUserFile(file);
  };

  const handleCreatUserFile = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    let formData = new FormData();
    formData.append("users_file", loadUserFile);
    const url = "users/import"

    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1]);
    }

    const userToken = MyConfigurationApi.tokenData().userToken;
    const response = await fetch(
      process.env.REACT_APP_API_DJANGO+"api/users/import",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken + "",
        },
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: formData,
      }
    ); 
    if (response.status === 200) {
      handleGetUsers();
      setOpenModalLoadUserFile(false);
      handleOpenAlert(
        "Los datos fueron registrados de forma correcta",
        "success"
      );
    } else {
      alert("Error al importar usuarios");
      handleOpenAlert("Hubo un error al cargar los datos", "error");
    }
    return response;
  };

  const changePassword = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyUsers.changeUserPassword(userId, newPassword);

    handleOpenAlert(
      "Los datos fueron registrados de forma correcta",
      "success"
    );
    setOpenModalChangePassword(false);
    handleGetUsers();
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  return (
    <>
      <Box >
        <ReturnBack/>

        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />

        <Container>
          <Box>
            <Grid container>
              <Grid item md={12}>
                <ButtonMailboxControla
                  originUrl={"/settings/users"}
                />
              </Grid>

              <Grid item md={7}>
                <SubtitleText text={"Usuarios"} color={grey}></SubtitleText>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <BannerButtonControla
                  color1={"#8FBCFF"}
                  color2={"#2F67BC"}
                  title={"Mis Usuarios"}
                  image={logoCompany}
                />
              </Grid>
            </Grid>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="start"
              sx={{ marginTop: 3, marginBottom: 3 }}
            >
              <Box>
                <ButtonControla
                  roles={rolesButtonCreateUser}
                  iconButton={<Add />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Crear usuario"}
                  isNavegationOrigin={true}
                  url={"/settings/users/create"}
                />
              </Box>
              <Box>
                <ButtonControla
                  roles={rolesButtonImportUser}
                  iconButton={<UploadFile />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Importar usuarios"}
                  functionOnClick={handleOpenModalUpload}
                />
              </Box>
            </Stack>

            <TableControla
              loading={openLoading}
              tabs={[
                {
                  label: "Habilitados",
                  columnNames: ["Nombre completo", "Documento de identidad", "Email / Usuario"]
                },
                {
                  label: "Deshabilitados",
                  columnNames: ["Nombre completo", "Documento de identidad", "Email / Usuario"]
                }
              ]}
            >
              <ListUsersActives
                usersActivesRows={usersActivesRows}
                colorCell={purple}
                setOpenModalChangePassword={setOpenModalChangePassword}
                setUserId={setUserId}
              />

              <ListUsersInactives
                usersInactivesRows={usersInactivesRows}
                colorCell={purple}
                setOpenModalChangePassword={setOpenModalChangePassword}
                setUserId={setUserId}
              />

            </TableControla>

          </Box>
        </Container>

         <Dialog
          open={openModalChangePassword}
          onClose={() => setOpenModalChangePassword(false)}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Cambiar Contraseña
            </Typography>
          </DialogTitle>
          <form onSubmit={changePassword}>
            <DialogContent sx={{ paddingTop: "0" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack display="flex" gap={4} flexDirection="row" justifyContent="center" alignItems="end">
                  <InputControlaVertical
                    text={"Nueva Contraseña"}
                    modalType={true}
                    inputValue={setNewPassword}
                    value={newPassword}
                    type={visibilityPassword ? "text" : "password"}
                  />

                  <Box sx={{ padding: '5px 0' }}>
                    <IconButton
                      onClick={togglePassword}
                    >
                      {!visibilityPassword ? (
                        <Visibility/>
                        ) : (
                          <VisibilityOff/>
                        )
                      }
                      
                    </IconButton>
                  </Box>
                </Stack>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#6969D6",
                  backgroundColor: "#CBCBFF",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
                onClick={() => setOpenModalChangePassword(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#169073",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
              >
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        {/* Dialog para agregar archivo de usuarios */}
        <Dialog
          open={openModalLoadUserFile}
          onClose={() => setOpenModalLoadUserFile(false)}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Agregar usuarios
            </Typography>
          </DialogTitle>
          <form onSubmit={handleCreatUserFile}>
            <DialogContent sx={{ paddingTop: "0" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <InputControlaVertical
                  text={"Agregar archivo formato excel"}
                  inputType={"file"}
                  inputValue={setLoadUserFile}
                  value={loadUserFile}
                  required
                /> */}
                <input type="file" onChange={handleFileChange} name="file" />
              </Grid>
              {/* <Grid>
              <button className={`btn`}>
                <a
                  href={excel}
                  target="_blank"
                  rel="noopener noreferrer"
                  download="plantilla.xlsx"
                >
                 Descarga plantilla excel
                </a>
                </button> */}
              <Grid>
                <a href="/assets/images/info/plantilla.xlsx" target="_blank">
                  <ButtonControla                  
                    iconButton={<FileDownload sx={{ color: "white" }} />}
                    textButton={"Descargar plantilla"}
                    backgroundColor={"#ffff"}
                    backgroundColorHover={"#ffff"}
                  />
                  Descargar plantilla
                </a>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#6969D6",
                  backgroundColor: "#CBCBFF",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
                onClick={() => setOpenModalLoadUserFile(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#169073",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
              >
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </>
  );
}

export default Index;
