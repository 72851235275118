// colocar lo de Workaccidents.jsx
import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import SubtitleText from "../../components/text/subtitleText";
import BannerControla from "../../components/cards/bannerControla";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import MyRit from "../../api/MyRit";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import { useAppContext } from "../../context/AppContext";
import TableControla from "../../components/tables/table-controla";
import ListRit from "./components/list-rit";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import ReturnBack from "../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const purple = "#8B8BD8";

function Index() {
  const { permissions } = useAppContext();

  const rolesAllPermissions = permissions.DOC_RIT_AGREGAR;
  // alerts
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
  const [titleText, setTitleText] = React.useState("");
  const [contentText, setContentText] = React.useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState("");
  const [buttonActionType, setButtonActionType] = React.useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdRit(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteRit":
        handleDeleteRit();
        break;
      case "archiveRit":
        handleArchiveRit();
        break;
      case "dearchiveRit":
        handleDeArchiveRit();
        break;
      case "publishRit":
        handlePublishRit();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  const [openModalAddMatrix, setOpenModalAddMatrix] = React.useState(false);
  const handleOpenModalAddMatrix = () => {
    setName("");
    setOpenModalAddMatrix(true);
  };

  const [rit, setRit] = React.useState([]);
  const [name, setName] = React.useState("");
  const [file, setFile] = React.useState("");

  const handleAddMatrix = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyRit.createRit({ name, file });
    handleGetRit();
    setOpenModalAddMatrix(false);
    handleOpenAlert("Se crearon los datos de forma correcta", "success");
    return response;
  };

  const handleGetRit = async () => {
    handleOpenLoading();

    const res = MyConfigurationApi.userData();
    const rolesUserLogged = res.roles;
    let isPermissionsAll= false;

    rolesAllPermissions?.forEach(r => {
      if(rolesUserLogged.some(rol => rol == r)){
        isPermissionsAll = true;
      }
    });

    const response = await MyRit.getRit();

    if(!isPermissionsAll){
      const res = response.filter((rit) => rit.register_status == 4)
      setRit(res);
    } else{
      setRit(response);
    }

    setOpenLoading(false);
    return response;
  };

  // eliminar
  const [idRit, setIdRit] = React.useState();

  const handleDeleteRit = async () => {
    handleOpenLoading();
    const response = await MyRit.deleteRit({
      idRit: idRit,
    });

    if(response.success == true){
      handleGetRit();
      handleOpenAlert("Los datos fueron eliminados de forma correcta", "success");
    }else{
      handleOpenAlert("Ocurrió un error", "error");
    }
   
    return response;
  };

  //
  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );
  const [documentType, setDocumentType] = React.useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleArchiveRit = async () => {
    handleOpenLoading();
    const response = await MyRit.changeRitStatus({
      idRit: idRit,
      registerStatus: 5,
    });
    handleGetRit();
  };

  const handleDeArchiveRit = async () => {
    handleOpenLoading();
    const response = await MyRit.changeRitStatus({
      idRit: idRit,
      registerStatus: 6,
    });
    handleGetRit();
  };

  const handlePublishRit = async () => {
    handleOpenLoading();
    const response = await MyRit.changeRitStatus({
      idRit: idRit,
      registerStatus: 4,
    });

    handleGetRit();
    handleOpenAlert("Se publicó el rit con éxito", "success");
  };

  React.useEffect(() => {
    handleGetRit();
  }, [rolesAllPermissions]);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid item xs={12} md={12}>
            <ButtonMailboxControla
              originUrl={"/documentation/rit"}
            />
          </Grid>
          <Grid container>
            <Grid item xs={7} md={7}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
            </Grid>

            <Grid item xs={12} md={12}>
              <BannerControla
                image="/assets/images/banners/documents/bannerritmain.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="RIT- Reglamento Interno de Trabajo"
              />
            </Grid>

          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                roles={permissions.DOC_RIT_AGREGAR}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar nuevo RIT"}
                functionOnClick={handleOpenModalAddMatrix}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid item md={12}>

                <TableControla
                  loading={openLoading}
                  tabs={[
                    {
                      columnNames: ["Nombre del documento", "Estado", "Acciones"]
                    }
                  ]}
                >

                  <ListRit
                    rit={rit}
                    colorCell={purple}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                  />

                </TableControla>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalAddMatrix}
        onClose={() => setOpenModalAddMatrix(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Crear nuevo RIT
          </Typography>
        </DialogTitle>
        <form onSubmit={handleAddMatrix}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre:"}
                  inputType={"text"}
                  inputValue={setName}
                  value={name}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Archivo"}
                  inputType={"file"}
                  inputValue={setFile}
                  value={file}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalAddMatrix(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Index;
