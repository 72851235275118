import React, { useEffect, useState } from 'react';
import { Container, Grid, Stack, Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Add } from '@mui/icons-material';
import MyContractors from '../../api/MyContractors';
import LoadingControla from '../../components/load/loadingControla';
import DialogConfirmControla from '../../components/dialog/dialogConfirmControla';
import SubtitleText from '../../components/text/subtitleText';
import ButtonControla from '../../components/buttons/buttonController';
import TableControla from '../../components/tables/table-controla';
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import { useAppContext } from '../../context/AppContext';
import DialogTitleControla from '../../components/dialog/dialogTitleControla';
import ListContractors from './components/list-contractors';
import ReturnBack from '../../components/buttons/returnBackControla';

const colorTitle = "#9191B0";

function Contractors() {
    const { permissions } = useAppContext();

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Confirm dialog
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");
    const [idContractor, setIdContractor] = useState("");

    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, buttonActionType, idContractor) => {
        setOpenModalConfirm(true);
        setIdContractor(idContractor);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case "delete":
                handleDeleteContractorById();
                break;
        }
    }

    // States for contractors
    const [rowsContractors, setRowsContractors] = useState([]);

    // Form data
    const [companyName, setCompanyName] = useState("");
    const [ruc, setRuc] = useState("");
    const [address, setAddress] = useState("");
    const [economicActivityType, setEconomicActivityType] = useState("");
    const [totalWorkers, setTotalWorkers] = useState("");

    // Modal for creating new contractor
    const [openModalCreate, setOpenModalCreate] = useState(false);

    const handleOpenModalCreate = () => {
        setOpenModalCreate(true);
    }

    const handleCloseModalCreate = () => {
        setOpenModalCreate(false);
    }

    // Modal for editing contractor
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [editContractor, setEditContractor] = useState(null);

    const handleOpenModalEdit = (contractor) => {
        setEditContractor(contractor);
        setCompanyName(contractor.company_name);
        setRuc(contractor.ruc);
        setAddress(contractor.address);
        setEconomicActivityType(contractor.economic_activity_type);
        setTotalWorkers(contractor.total_workers);
        setOpenModalEdit(true);
    }

    const handleCloseModalEdit = () => {
        setOpenModalEdit(false);
        setEditContractor(null);
    }

    const handlePostUpdateContractor = async (e) => {
        e.preventDefault();
        handleOpenLoading();

        const data = {
            company_name: companyName,
            ruc,
            address,
            economic_activity_type: economicActivityType,
            total_workers: totalWorkers
        }

        const resp = await MyContractors.updateContractorById(data, editContractor.id_contractors);

        if (resp.success) {
            await getContractors();
            handleOpenAlert("Contractor updated successfully", "success");
        } else {
            handleOpenAlert("Error updating contractor", "error");
        }

        handleCloseModalEdit();
    }

    // Handle create contractor
    const handlePostCreateContractor = async (e) => {
        e.preventDefault();
        handleOpenLoading();

        const data = {
            company_name: companyName,
            ruc,
            address,
            economic_activity_type: economicActivityType,
            total_workers: totalWorkers
        }

        const resp = await MyContractors.postContractor(data);

        if (resp.success) {
            await getContractors();
            handleOpenAlert("Contractor created successfully", "success");
        } else {
            handleOpenAlert("Error creating contractor", "error");
        }

        handleCloseModalCreate();
    }

    // Handle delete contractor
    const handleDeleteContractorById = async () => {
        handleOpenLoading();

        const resp = await MyContractors.deleteContractorById(idContractor);

        if (resp.success) {
            await getContractors();
            handleOpenAlert("Contractor deleted successfully", "success");
        } else {
            handleOpenAlert("Error deleting contractor", "error");
        }
        handleCloseModalConfirm();
    }

    // Fetch contractors
    const getContractors = async () => {
        handleOpenLoading();

        const resp = await MyContractors.getContractors() ?? [];
        setRowsContractors(resp.data);

        setOpenLoading(false);
    }

    useEffect(() => {
        getContractors();
    }, []);

    return (
        <Box sx={{ height: '100%' }}>
            <ReturnBack/>
            
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

            <Container>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <SubtitleText text={"Contratistas"} color={colorTitle} />
                    </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    roles={permissions.CONTRACTOR_ADD}
                                    iconButton={<Add sx={{ color: "white" }} />}
                                    textButton={"Agregar Contratista"}
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#1976d2"}
                                    functionOnClick={handleOpenModalCreate}
                                />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TableControla
                            loading={openLoading}
                            tabs={[
                                {
                                    label: "Acciones",
                                    columnNames: ["Nombre de la Compañia", "RUC", "Domicilio", "Tipo de actividad Economica", "Numero de trabajadores"]
                                },
                            ]}
                        >
                          <ListContractors
                            rowsContractors={rowsContractors}
                            handleOpenModalConfirm={handleOpenModalConfirm}
                            handleOpenModalEdit={handleOpenModalEdit}
                          />
                        </TableControla>
                    </Grid>
                </Grid>
            </Container>

            {/* Dialog for creating contractor */}
            <Dialog
                open={openModalCreate}
                onClose={handleCloseModalCreate}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText={"Agregar Nuevo Contratista"}
                    functionOnClose={handleCloseModalCreate}
                />
                <form onSubmit={handlePostCreateContractor}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputControlaVertical
                                    text={"Nombre de la Compañia"}
                                    inputType={"text"}
                                    inputValue={setCompanyName}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControlaVertical
                                    text={"RUC"}
                                    inputType={"text"}
                                    inputValue={setRuc}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControlaVertical
                                    text={"Domicilio"}
                                    inputType={"text"}
                                    inputValue={setAddress}
                                    modalType={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControlaVertical
                                    text={"Tipo de actividad Economica"}
                                    inputType={"text"}
                                    inputValue={setEconomicActivityType}
                                    modalType={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControlaVertical
                                    text={"Numero de trabajadores"}
                                    inputType={"number"}
                                    inputValue={setTotalWorkers}
                                    modalType={true}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalCreate}
                            />
                            <ButtonControla
                                textButton={"Crear"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

            {/* Dialog for editing contractor */}
            <Dialog
                open={openModalEdit}
                onClose={handleCloseModalEdit}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText={"Editar Contratista"}
                    functionOnClose={handleCloseModalEdit}
                />
                <form onSubmit={handlePostUpdateContractor}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputControlaVertical
                                    text={"Nombre de la Compañia"}
                                    inputType={"text"}
                                    inputValue={setCompanyName}
                                    modalType={true}
                                    value={companyName}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControlaVertical
                                    text={"RUC"}
                                    inputType={"text"}
                                    inputValue={setRuc}
                                    modalType={true}
                                    value={ruc}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControlaVertical
                                    text={"Domicilio"}
                                    inputType={"text"}
                                    inputValue={setAddress}
                                    modalType={true}
                                    value={address}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControlaVertical
                                    text={"Tipo de actividad Economica"}
                                    inputType={"text"}
                                    inputValue={setEconomicActivityType}
                                    modalType={true}
                                    value={economicActivityType}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControlaVertical
                                    text={"Numero de trabajadores"}
                                    inputType={"number"}
                                    inputValue={setTotalWorkers}
                                    modalType={true}
                                    value={totalWorkers}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalEdit}
                            />
                            <ButtonControla
                                textButton={"Actualizar"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}

export default Contractors;
