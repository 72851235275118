import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import {BorderColorOutlined} from "@mui/icons-material";

import { useAppContext } from "../../../context/AppContext";

function ListUsersInactives(props) {
    const {permissions} = useAppContext();
    
    const {
        usersInactivesRows,
        colorCell
    } = props

    return (
        <TableBody >
            {usersInactivesRows.map((rowUser) => (
                <TableRow
                    hover
                    key={rowUser.id_users}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell component="th" scope="row" sx={{ color: colorCell }}>
                        {rowUser.first_name + " " + rowUser.last_name}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>{rowUser.document}</TableCell>
                    <TableCell sx={{ color: colorCell }}>{rowUser.email}</TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={permissions.CONF_USER_EDITAR}
                                icon={
                                    <BorderColorOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                isNavegationOrigin={true}
                                directionUrl={"/settings/users/edit?idUsers=" + rowUser.id_users}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );

}
export default ListUsersInactives;