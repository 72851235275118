import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Stack,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  Add,
  Upload,
} from "@mui/icons-material";
import Container from "@mui/material/Container";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../components/text/subtitleText";
import BannerControla from "../../components/cards/bannerControla";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import MySsoPolitics from "../../api/MySsoPolitics";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import ListBorrador from "./components/list-borrador";
import ListActives from "./components/list-actives";
import ListArchived from "./components/list-archived";
import { useAppContext } from "../../context/AppContext";
import TableControla from "../../components/tables/table-controla";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../components/buttons/returnBackControla";

const blue = "#034AFF";
const purple = "#8B8BD8";

function Index() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");
  const [idSsoPolitics, setIdSsoPolitics] = useState();

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdSsoPolitics(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "workers":
        handleUpdateStatusSsoPolitics(idSsoPolitics, 7);
        break;
      case "publish":
        handleUpdateStatusSsoPolitics(idSsoPolitics, 4);
        break;
      case "committee":
        handleUpdateStatusSsoPolitics(idSsoPolitics, 3);
        break;
      case "management":
        handleUpdateStatusSsoPolitics(idSsoPolitics, 8);
        break;
      case "archived":
        handleUpdateStatusSsoPolitics(idSsoPolitics, 5);
        break;
      case "unarchived":
        handleUnarchiveStatusSooPolitics(idSsoPolitics);
        break;
      case "delete":
        handleDeleteSsoPolitics(idSsoPolitics);
        break;
      case "delete_template":
        handleDeleteSsoPoliticsTemplate(idSsoPolitics);
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  // Abrir diálogo de cargar archivo

  const [develop, setDevelop] = useState(null);
  const [date, setDate] = useState(null);
  const [title, setTitle] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const [rowsSsoPolitics, setRowsSsoPolitics] = useState([]);
  const [rowsSso, setRowsSso] = useState([]);

  // Abrir modal subir documento
  const [openModalUploaFile, setOpenModalUploadFile] = useState(false);
  const handleOpenModalUploadFile = () => {
    setDevelop(null);
    setDate(null);
    setTitle(null);
    setDocumentUrl(null);
    setOpenModalUploadFile(true);
  };

  // editar rist document
  const [idRist, setIdRist] = useState();
  const [openModalUploaFileEdit, setOpenModalUploadFileEdit] = useState(false);
  const handleOpenModalUploadFileEdit = (idRist) => {
    setIdRist(idRist);
    handleEditSsoPolitics(idRist);
    setOpenModalUploadFileEdit(true);
  };

  const handleEditSsoPolitics = async (idRist) => {
    const response = await MySsoPolitics.editSsoPolitics({
      idSsoPolitics: idRist,
    });
    setDevelop(response.develop);
    setTitle(response.title);
    setDate(response.date_develop);
    return response;
  };
  const handleUpdateSsoPolitics = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MySsoPolitics.UpdateSsoPolitics({
      idSsoPolitics: idRist,
      develop: develop,
      title: title,
      date: date,
      document_url: documentUrl,
    });

    setOpenModalUploadFileEdit(false);
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    handleGetSsoPolitics();
    navigate("/documentation/ssopolitics?idTabs=1");
    return response;
  };


  //   crear
  const handleCreateSsoPolitics = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MySsoPolitics.createSsoPolitics({
      develop: develop,
      title: title,
      date: date,
      document_url: documentUrl,
    });
    if (response.success !== false) {
      setOpenModalUploadFile(false);
      handleOpenAlert("El documento se ha creado de forma correcta", "success");
      handleGetSsoPolitics();
     
      navigate("/documentation/ssopolitics?idTabs=1");
    } else {
      handleOpenAlert("Ocurrió un error al crear el archivo", "error");
    }
    return response;
  };
  // Eliminar

  const handleDeleteSsoPolitics = async () => {
    handleOpenLoading();
    const response = await MySsoPolitics.deleteSsoPolitics({
      idSsoPolitics: idSsoPolitics,
    });
    handleOpenAlert("El documento se eliminó de forma correcta", "success");
    handleGetSsoPolitics();
    return response;
  };

  //Eliminar plantilla

  const handleDeleteSsoPoliticsTemplate = async () => {
    handleOpenLoading();
    const response = await MySsoPolitics.deleteSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idSsoPolitics,
    });
    handleOpenAlert("El documento se elimnó de forma correcta", "success");
    handleGetSsoPolitics();
    return response;
  };

  // Editar estado de la política SSO
  const handleUpdateStatusSsoPolitics = async (id, status) => {
    handleOpenLoading();

    const response = await MySsoPolitics.updateStatusSsoPolitics({
      idSsoPolitics: id,
      status: status,
    });

    setOpenLoading(false);
    if (response.register_status == 4) {
      navigate("/documentation/ssopolitics?idTabs=1");
    }

    handleGetSsoPolitics();
  };

  const handleUnarchiveStatusSooPolitics = async (id) => {
    handleOpenLoading();

    const response = await MySsoPolitics.updateSsoPoliticsTemplate({
      idSsoPoliticsTemplate: id,
      develop: "",
      revised: "",
      approved: "",
      idUsersApproved: "",
      idUsersRevised: "",
      idUsersDevelop: "",
      dateApproved: "",
      dateDevelop: "",
      dateRevised: "",
      managerSignDate: "",
      managerSign: "",
      developSign: "",
      revisedSign: "",
      approvedSign: "",
      registerStatus: 1,
    });

    navigate("/documentation/ssopolitics?idTabs=0");
  };

  // Diálogo para template
  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const handleOpenModalTemplate = () => {
    setOpenModalTemplate(true);
  };

  // Dialog para ver plantilla
  const [openModalTemplateView, setOpenModalTemplateView] = useState(false);

  //usar plantilla
  const handleUseTemplate = async (event) => {
    event.preventDefault();
    navigate("/documentation/ssopolitics/template");
  };

  // mostrar SsoPolitics docuemntos

  const [idUserLogged, setIdUserLogged] = useState();
  const handleGetSsoPolitics = async (inputYear = undefined) => {
    handleOpenLoading();

    const responsable = MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);

    const response = await MySsoPolitics.getSsoPolitics();

    if (response.success === false) {
      setOpenLoading(false);
      handleOpenAlert("Ocurrio un error al listar los SsoPolitics", "error");
    } else {
      const templates = await MySsoPolitics.getSsoPoliticsTemplate();

      // prueba 
      setRowsSsoPolitics(templates);

      const tmp = response.concat(templates);
      setRowsSso(tmp);

      setOpenLoading(false);
    }
    return response;
  };

  useEffect(() => {
    handleGetSsoPolitics();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <Box>
          <Grid container>
            <Grid item xs={12} md={12}>
              <ButtonMailboxControla
                originUrl={"/documentation/ssopolitics"}
              />
            </Grid>

            <Grid item xs={7} md={7}>
              <SubtitleText
                text={"Procedimientos"}
                color={"#9191B0"}
              ></SubtitleText>
            </Grid>

            <Grid item xs={12} md={12}>
              <BannerControla
                image="/assets/images/banners/documents/bannerpoliticasso.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Políticas de SSO"
              />
            </Grid>

          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
              <Stack direction={"row"} spacing={2}>
                <Box>
                  <ButtonControla
                    roles={permissions.DOC_SSO_BORRADOR_AGREGAR}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#1cb591"}
                    backgroundColorHover={"#169073"}
                    textButton={"Agregar usando plantilla"}
                    functionOnClick={handleOpenModalTemplate}
                  />
                </Box>
                <Box>
                  <ButtonControla
                    roles={permissions.DOC_SSO_BORRADOR_SUBIR_DOC}
                    iconButton={<Upload sx={{ color: "white" }} />}
                    textButton={"Subir mi documento"}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#1976d2"}
                    functionOnClick={handleOpenModalUploadFile}
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={3} xl={3} sx={{ mt: 2 }}>
              {" "}
            </Grid>

            {/* filtrar por año */}
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <Grid container spacing={2}></Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TableControla
                loading={openLoading}
                tabs={[
                  {
                    label: "Borrador",
                    columnNames: ["Nombre del documento", "Estado"]
                  },
                  {
                    label: "Activos",
                    columnNames: ["Nombre del documento", "Estado"]
                  },
                  {
                    label: "Archivados",
                    columnNames: ["Nombre del documento","Tipo"]
                  }
                ]}
              >
                <ListBorrador
                  rowsSsoPolitics={rowsSsoPolitics}
                  colorCell={purple}
                  idUserLogged={idUserLogged}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />
                <ListActives
                  rowsSso={rowsSso}
                  colorCell={purple}
                  handleOpenModalUploadFileEdit={handleOpenModalUploadFileEdit}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />
                <ListArchived
                  rowsSso={rowsSso}
                  colorCell={purple}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />
              </TableControla>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Dialog subir archivo */}
      <Dialog
        open={openModalUploaFile}
        onClose={() => setOpenModalUploadFile(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Políticas SSO{" "}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleCreateSsoPolitics}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setDevelop}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de elaboración:"}
                  inputType={"date"}
                  inputValue={setDate}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitle}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalUploadFile(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog editar  archivo */}
      <Dialog
        open={openModalUploaFileEdit}
        onClose={() => setOpenModalUploadFileEdit(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Edita - Políticas SSO{" "}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleUpdateSsoPolitics}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setDevelop}
                  value={develop}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de elaboración:"}
                  inputType={"date"}
                  inputValue={setDate}
                  modalType={true}
                  value={date}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitle}
                  modalType={true}
                  value={title}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalUploadFileEdit(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog plantilla */}
      <Dialog
        open={openModalTemplate}
        onClose={() => setOpenModalTemplate(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Te presentamos unas plantillas para facilitar la elaboración de tu
            documento
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalTemplate(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() =>  navigate("/documentation/ssopolitics/template/menu")}
          >
            Ver
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modelo de plantilla */}
      <Dialog
        open={openModalTemplateView}
        onClose={() => setOpenModalTemplateView(false)}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0, marginTop: "15px" }}
      >
        <DialogTitleControla titleText="Política de Seguridad y Salud Ocupacional" />

        <form onSubmit={handleUseTemplate}>
          <DialogContent>
            <Grid container>
              <Grid item md={12}>
                <Typography>
                  NOMBRE_EMPRESA, empresa dedicada a INGRESAR OBJETO SOCIAL,
                  convencida que su capital más importante es su personal, ha
                  elaborado la siguiente política de seguridad y salud en
                  trabajo, mediante la que se compromete a:
                </Typography>
              </Grid>
              <Grid item md={12}>
                - Desarrollar sus actividades protegiendo la integridad de sus
                colaboradores, proveedores y la de terceros en las instalaciones
                pertenecientes a la empresa, mediante la prevención de las
                lesiones, dolencias, enfermedades e incidentes relacionados con
                el trabajo, identificando los peligros, evaluando los riesgos
                relacionados a las actividades de la empresa y estableciendo
                medidas de control.
              </Grid>
              <Grid item md={12}>
                - Cumplir con la normativa legal vigente, regulaciones internas,
                compromisos voluntarios suscritos por la organización en materia
                de seguridad y salud en el trabajo, así como estándares
                internacionales.
              </Grid>
              <Grid item md={12}>
                - Asegurar la consulta y participación activa de nuestro
                personal y de sus representantes en todos los aspectos del
                Sistema de Gestión de Seguridad y Salud en el Trabajo de nuestra
                empresa.
              </Grid>
              <Grid item md={12}>
                - Informar, comunicar y poner a disposición de manera
                responsable y oportuna a nuestro personal, sus representantes y
                grupos de interés los aspectos del Sistema de Gestión de
                Seguridad y Salud en el Trabajo de nuestra empresa para eliminar
                los peligros y reducir los riesgos.
              </Grid>
              <Grid item md={12}>
                - Capacitar, entrenar y comprometer a nuestro personal con la
                cultura de prevención de la seguridad y salud en todos los
                aspectos del Sistema de Gestión de Seguridad y Salud en el
                Trabajo de nuestra empresa.
              </Grid>
              <Grid item md={12}>
                - Mejorar continuamente el desempeño del Sistema de Gestión de
                Seguridad y Salud en el trabajo e integrarlo a las demás
                actividades y otros sistemas de la empresa.
              </Grid>
              <Grid item md={12}>
                Firma Fecha:
              </Grid>
              <Grid item md={12}>
                ______________________
              </Grid>
              <Grid item md={12}>
                Gerente General o equivalente (director / presidente / CEO,
                etc.)
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalTemplateView(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Usar plantilla
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog editar  archivo */}
      <Dialog
        open={openModalUploaFileEdit}
        onClose={() => setOpenModalUploadFileEdit(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Editar Política SSO
          </Typography>
        </DialogTitle>

        <form onSubmit={handleUpdateSsoPolitics}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setDevelop}
                  value={develop}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de elaboración:"}
                  inputType={"date"}
                  inputValue={setDate}
                  modalType={true}
                  value={date}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitle}
                  modalType={true}
                  value={title}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalUploadFileEdit(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Index;
