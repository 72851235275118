import React, { createContext, useContext, useEffect, useState } from 'react';
import MyConfigurationModulesApi from '../api/MyConfigurationModules';
import MyConfigurationApi from '../api/MyConfigurationApi';
import MyPermission from "../api/MyPermissions";

const AppContext = createContext();

export function AppProvider({ children }) {
 
  const [configurationModules, setConfigurationModules] = useState([]);
  const [logoCompany, setLogoCompany] = useState(MyConfigurationApi.companyData().logo_url);
  const [sectionsModules, setSectionsModules] = useState([]);
  const [routesModules, setRoutesModules] = useState([]);
  const [loadingPermissions, setLoadingPermissions] = useState(false);

  const getSectionsModules = async ()  => {
    const response = await MyConfigurationModulesApi.getSectionsModules(MyConfigurationApi.companyData().id_companies)

    setSectionsModules(response?.sections ?? [])

    if (response?.sections) {
      const dataModulesBySection = response?.sections.map(item => {
        return {
          id_section: item.id_section,
          modules: item.modules
        }
      })
  
      setConfigurationModules(dataModulesBySection)
      setRoutesModules(response?.routesModules ?? [])
    }
  }

  //roles de componentes
  const [permissions, setPermissions] = useState({});
  const getRolesComponents = async()=>{
    setLoadingPermissions(true)
    const resp = await MyPermission.getPermissions();
    setPermissions(resp);
    setLoadingPermissions(false)
  }

  useEffect(()=>{
    getRolesComponents();
  },[logoCompany]);

  return (
    <AppContext.Provider 
      value={{
        configurationModules,
        setConfigurationModules,
        sectionsModules,
        setSectionsModules,
        getSectionsModules,
        routesModules,
        setRoutesModules,
        getRolesComponents,
        permissions,
        setPermissions,
        logoCompany,
        setLogoCompany,
        loadingPermissions
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
