import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import {
    VisibilityOutlined,
    Unarchive,
  } from "@mui/icons-material";
import { useAppContext } from "../../../../../context/AppContext";
import { useNavigate } from "react-router-dom";

function ListArchived(props) {
    const {permissions} = useAppContext();
    const navigate =  useNavigate();

    const {
        rowsArchives,
        documentType,
        handleOpenModalConfirm
    } = props

    return (
        <TableBody
            sx={{ display: documentType !== 2 ? "none" : "" }}
        >
            {rowsArchives.map((rowsArchives) => (
                <TableRow
                    hover
                    key={rowsArchives.id_ats}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell component="th" scope="row">
                        {rowsArchives.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {rowsArchives.document_url != null &&
                            rowsArchives.document_url != undefined
                            ? "Archivo"
                            : "Sistema"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {rowsArchives.status}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {
                                rowsArchives.document_url !== null ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_ATS_ARCHI_VER}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={rowsArchives.document_url}
                                        directionTarget={"_blank"}
                                    />

                                ) : (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_ATS_ARCHI_VER}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={true}
                                        directionUrl={"/ats/edit?id_ats=" + rowsArchives.id_ats}
                                        paramsIntUrl={{ readOnly: true }
                                        }
                                    />
                                )
                            }

                            <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_ATS_ARCHI_DESARCHIVAR}
                                icon={<Unarchive sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                backgroundColorHover={"#FF881E"}
                                textTooltip={"Desarchivar"}
                                functionOnClick={() =>
                                    handleOpenModalConfirm(
                                        rowsArchives.id_ats,
                                        "¿Estás seguro de desarchivar el ATS?",
                                        'ATS a desarchivar:  "' +
                                        rowsArchives.name +
                                        '"',
                                        "Si, desarchivar",
                                        "unarchived"
                                    )
                                }
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );

}
export default ListArchived;