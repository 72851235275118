import Login from "../../src/login/main";
import HomeView from "../components/home/homeView";

import Pets from "../documentation/documents/procedures/pets";
import PetsEdit from "../documentation/documents/procedures/pets/edit.jsx";
import PetsModels from "../documentation/documents/procedures/pets/model.jsx";

import Iperc from "../documentation/documents/procedures/iperc/iperc.jsx";
import Standards from "../standards";
import ModelStandar from "../standards/model.jsx";
import EditStandar from "../standards/edit.jsx"

import Sso from "../documentation/programs/sso/index.jsx";
import EditPrograms from "../documentation/programs/sso/editprograms.jsx";
import ObjectiveGeneral from "../documentation/programs/sso/objectivegeneral.jsx";
import ProgramsExtends from "../documentation/programs/sso/extends";

import ProgramsTraining from "../documentation/programs/training/index.jsx";
import TrainingEditPrograms from "../documentation/programs/training/editprograms.jsx";
import TrainingObjectiveGeneral from "../documentation/programs/training/objectivegeneral.jsx";
import TrainingProgramsExtends from "../documentation/programs/training/extends";

// documentos
import LegalMatrix from "../documentation/legalmatrix/index.jsx";
import RiskMap from "../documentation/riskmap/index.jsx";
import Rit from "../documentation/rit/index.jsx";


// hojas de seguridad

import SecuritySheetExplosives from "../documentation/securitysheet/explosives/index.jsx";
import SecuritySheetGases from "../documentation/securitysheet/gases/index.jsx";
import SecuritySheetFlammableLiquid from "../documentation/securitysheet/flammableliquid/index.jsx";
import SecuritySheetFlammableSolids from "../documentation/securitysheet/flammablesolids/index.jsx";
import SecuritySheetOxidizingSubstances from "../documentation/securitysheet/oxidizingsubstances/index.jsx";
import SecuritySheetPoisonousSubstances from "../documentation/securitysheet/poisonoussubstances/index.jsx";
import SecuritySheetRadioactiveMaterials from "../documentation/securitysheet/radioactivematerials/index.jsx";
import SecuritySheetCorrosiveSubstances from "../documentation/securitysheet/corrosivesubstances/index.jsx";
import SecuritySheetMiscelaneous from "../documentation/securitysheet/miscelaneous/index.jsx";

import Risst from "../documentation/risst/index.jsx";
import RisstTemplate from "../documentation/risst/template.jsx";
// Políticas Sso
import SsoPolitics from "../documentation/ssopolitics/index.jsx";
import SsoPoliticsTemplate from "../documentation/ssopolitics/template.jsx";
import SSoPoliticsTemplateTwo from "../documentation/ssopolitics/template2.jsx";
import SsoPoliticsTemplateThree from "../documentation/ssopolitics/template3.jsx";
import SsoPoliticsTemplateMenu from "../documentation/ssopolitics/templateMenu.jsx";
import AnnualPlan from "../documentation/annualplan/index.jsx";
import AnnualPlanTemplate from "../documentation/annualplan/template.jsx";
// import SecuritySheetMiscelaneous from "./documentation/securitysheet/miscelaneous/index.jsx";


// registers


import RegistersAccidents from "../documentation/registers/generals/accidents";
import RegistersAccidentsEdit from "../documentation/registers/generals/accidents/edit.jsx";
import RegistersOccupationalDiseases from "../documentation/registers/generals/occupationaldiseases";
import RegistersOccupationalDiseasesEdit from "../documentation/registers/generals/occupationaldiseases/edit.jsx";
import RegistersIncidentsDangerousIncidents from "../documentation/registers/generals/incidentsdangerousincidents";
import RegistersIncidentsDangerousIncidentsEdit from "../documentation/registers/generals/incidentsdangerousincidents/edit.jsx";
import RegistersMonitoring from "../documentation/registers/generals/monitoring/index";
import RegistersMonitoringEdit from "../documentation/registers/generals/monitoring/edit";
import RegistersSecurityEmergencyEquipment from "../documentation/registers/generals/securityemergencyequipment/index";
import RegistersSecurityEmergencyEquipmentEdit from "../documentation/registers/generals/securityemergencyequipment/edit";
import RegistersInductionTrainingCoaching from "../documentation/registers/generals/inductiontrainingcoaching/index";
import RegistersInductionTrainingCoachingEdit from "../documentation/registers/generals/inductiontrainingcoaching/edit";
import RegistersAudits from "../documentation/registers/generals/audits/index";
import RegistersAuditsEdit from "../documentation/registers/generals/audits/edit";
import RegistersInspections from "../documentation/registers/generals/inspections/index";
import RegistersInspectionsEdit from "../documentation/registers/generals/inspections/edit";
import RegistersStatisticalData from "../documentation/registers/generals/statisticaldata/index";
import RegistersStatisticalDataEdit from "../documentation/registers/generals/statisticaldata/edit";

import RegisterHealthAndSafetyStatistics from "../documentation/registers/generals/healthAndSafetyStatistics/index.jsx";
import RegisterHealthAndSafetyStatisticsEdit from "../documentation/registers/generals/healthAndSafetyStatistics/edit2.jsx"

import RegistersCompetencyCertificate from "../documentation/registers/generals/competencycertificate/index";
import RegistersCompetencyCertificateEdit from "../documentation/registers/generals/competencycertificate/edit";

// mypes

import RegistersMyAccidentsOccupationalsIncidents from "../documentation/registers/mypes/accidentsoccupationalsincidents";
import RegistersMyAccidentsOccupationalsIncidentsEdit from "../documentation/registers//mypes/accidentsoccupationalsincidents/edit.jsx";
import RegistersMypeTracking from "../documentation/registers/mypes/tracking";
import RegistersMypeTrackingEdit from "../documentation/registers/mypes/tracking/edit.jsx";

import EvaluationSSO from "../documentation/registers/mypes/evaluationSSO";
import EvaluationSSOEdit from "../documentation/registers/mypes/evaluationSSO/edit.jsx"

// inductiontrainingcoaching
import MainPetar from "../documentation/documents/procedures/petar";
import EditPetar from "../documentation/documents/procedures/petar/editPetar";
import IndexAts from "../documentation/documents/procedures/ats";
import EditAts from "../documentation/documents/procedures/ats/edit";
import ModelEditAts from "../documentation/documents/procedures/ats/model";


//comite

import ElectionProcess from "../committee/electionProcess/index.jsx";
import ElectionProcessProcess from "../committee/electionProcess/process";
import Process from "../committee/process/index.jsx";
import ProcessStatus from "../committee/processStatus";

import RecommendationsBook from "../committee/proceedingsBook/recommendationsProceedingsBook/recommendationsBook.jsx";

//reuniones

import CurrentCommittee from "../committee/presentCommittee/currentCommittee/";
import InternalRegulationsCommittee from "../committee/presentCommittee/internalRegulationCommittee/internalRegulationsCommitee.jsx";
import TemplateMenuRegulations from "../committee/presentCommittee/internalRegulationCommittee/templateMenu.jsx";
import TemplateRegulations from "../committee/presentCommittee/internalRegulationCommittee/templateRegulations.jsx";

import ScheduleMeetings from "../committee/scheduleMeetings/scheduleMeetings";
import EditScheduleMeetings from "../committee/scheduleMeetings/editScheduleMeeting";
import ViewScheduleMeetings from "../committee/scheduleMeetings/viewScheduleMeeting";

//reuniones programadas pequeñas empresas
import ScheduleMeetingSmallCompany from "../proceddingsBookSmallCompany/scheduleMeetings/index.jsx"
import EditScheduledMeetingsSmallCompany from "../proceddingsBookSmallCompany/scheduleMeetings/editScheduledMeeting.jsx";
//actas para pequeñas empresas
import ProceedingBook from "../proceddingsBookSmallCompany/ProceddingsBook/proceedingBook/proceedingBook.jsx";
import Proceeding from "../proceddingsBookSmallCompany/ProceddingsBook/proceedingBook/proceeding.jsx";
import EditProceedingSmallCompany from "../proceddingsBookSmallCompany/ProceddingsBook/proceeding/editProceedingSmallCompany.jsx";
import InformationThemeProceedingSmallCompany from "../proceddingsBookSmallCompany/ProceddingsBook/proceeding/informationThemeProceedingSmallCompany.jsx";

import AddProceedingsBook from "../committee/proceedingsBook/addProceedingsBook/addProceedingsBook";

import ProceedingsMeeting from "../committee/proceedingsBook/addProceedingsBook/proceedingsMeeting/proceedingsMeeting";
import EditProceedingsMeeting from "../committee/proceedingsBook/addProceedingsBook/proceedingsMeeting/editProceedingsMeeting";
import ViewProceedingsMeeting from "../committee/proceedingsBook/addProceedingsBook/proceedingsMeeting/viewProceedingsMeeting";

import InformationTheme from "../committee/proceedingsBook/addProceedingsBook/proceedingsMeeting/informationTheme/informationTheme";
import ViewInformationTheme from "../committee/proceedingsBook/addProceedingsBook/proceedingsMeeting/informationTheme/viewInformationTheme";

// mailboxMeetings

import MailboxConsults from "../mailbox/consults/";
import MailboxReports from "../mailbox/reports/";
import MailboxCongratulations from "../mailbox/congratulations/";
import MailboxNotifications from "../mailbox/notifications/";

import ScheduleCalendar from "../committee/scheduleCalendar";
import ProcessStatusTwo from "../committee/processStatusTwo";
import ScheduleCalendarTwo from "../committee/scheduleCalendarTwo";
import ScheduleCalendarThree from "../committee/ScheduleCalendarThree";
import BaselineProcess from "../baseline/baselineProcess";
import BaselineStartProcess from "../baseline/baselineStartProcess";
import BaselineAnswers from "../baseline/baselineAnswers";
import BaselineAnswersTwo from "../baseline/baselineAnswersTwo";
import BaselineResults from "../baseline/baselineResults";
import Company from "../company/index.jsx";
import Headoffices from "../company/headoffices.jsx";
import Organizationchart from "../company/organizationchart.jsx";

import IndexEpps from "../administrator/epps";
import IndexEppsCategories from "../administrator/epps/categories";
import IndexBaseLineIndicators from "../administrator/baseline/index";
import IndexBaseLineLineaments from "../administrator/baseline/lineaments";
import ErrorViews from "../components/errorsViews";
import BaselineTools from "../baseline/baselineTools";
import BaselineDiagnosis from "../baseline/baselineDiagnosis";
import SettingsUsers from "../settings/users";
import SettingsUsersCreate from "../settings/users/create";
import SettingsUsersEdit from "../settings/users/edit";
import SettingsCompanyGeneralInformation from "../settings/company/generalInformation";
import SettingsCompanyOrganization from "../settings/company/organization";
import SettingsCompanyCertifications from "../settings/company/certifications";
import SettingsCampus from "../settings/campus";
import SettingsPassword from "../settings/pasword/index";
import EditModel from "../documentation/documents/procedures/petar/editModel";
import BaselineShowResults from "../baseline/baselineShowResults";
import AreasIndex from "../areas/index";
import OccupationalPositionsIndex from "../occupationalpositions/index";
import BaselineImprovementActions from "../baseline/baselineImprovementActions";

// activities.

import ActivitiesBaselineImpActions from "../activities/task/improvementactions/index";

import WorkersTasks from "../activities/task/TaksWorkers/workersTasks.jsx";
import TaskOccurrences from "../activities/task/TaksWorkers/taskOccurrences.jsx";
import MyTasks from "../activities/task/MyTasks/myTasks.jsx";

//trainings

import TrainingsSubjectIndex from "../trainings/subjects";
import TrainingsAllIndex from "../trainings/all/index";
import TrainingsAllEdit from "../trainings/all/edit";
import TrainingsAllWorkers from "../trainings/all/workers/workers";
import TrainingsAllEvidences from "../trainings/all/evidences";
import TrainingsAllMaterials from "../trainings/all/materials";
import TrainingsAllExams from "../trainings/all/exams/index";
import TrainingsAllExamsQuestions from "../trainings/all/exams/edit";

import TrainingsPersonalIndex from "../trainings/personal/index";
import TrainingsPersonalEdit from "../trainings/personal/edit";
import TrainingsPersonalWorkers from "../trainings/personal/123workers";
import TrainingsPersonalEvidences from "../trainings/personal/evidences";
import TrainingsPersonalMaterials from "../trainings/personal/materials";
import TrainingsPersonalExams from "../trainings/personal/exams/index";
import TrainingsPersonalExamsQuestions from "../trainings/personal/exams/edit";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { AuthGuard } from "../auth/auth-guard";



import Memberships  from "../crm/memberships.jsx";
import CompanyMember from "../crm/companyMember.jsx";
import ModulesBySectionMembership from "../crm/modulesBySectionMembership.jsx";
import SubModuleByModule from "../crm/subModuleByModule.jsx";
import Companies from "../crm/Company/companies.jsx";
import UserCompany from "../crm/Company/userCompany.jsx";

//tracking by company
import Tracking from "../crm/tracking/tracking.jsx";
import logsByIdCompany from "../crm/tracking/logsByIdCompany.jsx";
import LogsByIdCompany from "../crm/tracking/logsByIdCompany.jsx";

//buzon de sugerencia
import SuggestionsMailbox from "../suggestionsMailbox/suggestionsMailbox.jsx";
import BaselineStartProcessFree from "../baseline/BaselineStartProcessFree.jsx";
import { Register } from "../register/Register.jsx";
import Contractors from "../documentation/contractors/index.jsx";

// reportes estadisticos
import StatisticalReport from "../documentation/registers/generals/statisticalReports";


const routes = [
  {
    path: "/",
    element: <Navigate to={"/dashboard"}/>,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    type: 'guard',
    element: <AuthGuard />,
    children: [

      {
        path: "/memberships",
        element: <Memberships/>,
      },
      {
        path: "/companyMember",
        element: <CompanyMember />,
      },
      {
        path: "/modulesBySectionMembership",
        element: <ModulesBySectionMembership/>,
      },
      {
        path: "/submodulesByModule",
        element: <SubModuleByModule/>,
      },

      {
        path: "/crm/tracking",
        element: <Tracking/>,
      },
      {
        path: "/crm/logsByIdCompany",
        element: <LogsByIdCompany/>,
      },
      
      {
        path: "/company",
        element: <Companies/>,
      },
      {
        path: "/userCompany",
        element: <UserCompany/>,
      },

      {
        path: "/documentation/legalmatrix/",
        element: <LegalMatrix />,
      },
      {
        path: "/documentation/riskmap/",
        element: <RiskMap />,
      },
      {
        path: "/documentation/rit",
        element: <Rit />,
      },
      {
        path: "/documentation/securitysheet/explosives",
        element: <SecuritySheetExplosives />,
      },
      {
        path: "/documentation/securitysheet/gases",
        element: <SecuritySheetGases />,
      },
      {
        path: "/documentation/securitysheet/flammableliquid",
        element: <SecuritySheetFlammableLiquid />,
      },
      {
        path: "/documentation/securitysheet/flammablesolids",
        element: <SecuritySheetFlammableSolids />,
      },
      {
        path: "/documentation/securitysheet/oxidizingsubstances",
        element: <SecuritySheetOxidizingSubstances />,
      },
      {
        path: "/documentation/securitysheet/poisonoussubstances",
        element: <SecuritySheetPoisonousSubstances />,
      },
      {
        path: "/documentation/securitysheet/radioactivematerials",
        element: <SecuritySheetRadioactiveMaterials />,
      },
      {
        path: "/documentation/securitysheet/corrosivesubstances",
        element: <SecuritySheetCorrosiveSubstances />,
      },
      {
        path: "/documentation/securitysheet/miscelaneous",
        element: <SecuritySheetMiscelaneous />,
      },
      {
        path: "/documentation/risst",
        element: <Risst />,
      },
      {
        path: "/documentation/risst/template",
        element: <RisstTemplate />,
      },
      {
        path: "/documentation/ssopolitics",
        element: <SsoPolitics />,
      },
      {
        path: "/documentation/ssopolitics/template",
        element: <SsoPoliticsTemplate />,
      },
      {
        path: "/documentation/ssopolitics/templatetwo",
        element: <SSoPoliticsTemplateTwo />,
      },
      {
        path: "/documentation/ssopolitics/templatethree",
        element: <SsoPoliticsTemplateThree />,
      },
      {
        path: "/documentation/ssopolitics/template/menu",
        element: <SsoPoliticsTemplateMenu />,
      },
      {
        path: "/documentation/annualplan",
        element: <AnnualPlan />,
      },
      {
        path: "/documentation/annualplan/template",
        element: <AnnualPlanTemplate />,
      },
      {
        path: "/documentation/documents/standards",
        element: <Standards />,
      },
      {
        path: "/documentation/contractors",
        element: <Contractors />,
      },
      {
        path: "/documentation/documents/standards/model",
        element: <ModelStandar />,
      },
      {
        path: "/documentation/documents/standards/edit",
        element: <EditStandar />,
      },
      {
        path: "/documentation/documents/procedures/pets/",
        element: <Pets />,
      },
      {
        path: "/documentation/documents/procedures/pets/edit",
        element: <PetsEdit />,
      },
      {
        path: "/documentation/documents/procedures/pets/model",
        element: <PetsModels />,
      },
      {
        path: "/documentation/documents/procedures/iperc",
        element: <Iperc />,
      },
      {
        path: "/documentation/programs/sso",
        element: <Sso />,
      },
      {
        path: "/documentation/programs/sso/editprograms",
        element: <EditPrograms />,
      },
      {
        path: "/documentation/programs/sso/objectivegeneral",
        element: <ObjectiveGeneral />,
      },
      {
        path: "/documentation/programs/sso/extends",
        element: <ProgramsExtends />,
      },
      {
        path: "/documentation/programs/training",
        element: <ProgramsTraining />,
      },

      {
        path: "/documentation/programs/training/editprograms",
        element: <TrainingEditPrograms />,
      },
      {
        path: "/documentation/programs/training/objectivegeneral",
        element: <TrainingObjectiveGeneral />,
      },
      {
        path: "/documentation/programs/training/extends",
        element: <TrainingProgramsExtends />,
      },
      {
        path: "/documentation/programs/sso",
        element: <Sso />,
      },
      {
        path: "/documentation/programs/sso/editprograms",
        element: <EditPrograms />,
      },
      {
        path: "/documentation/programs/sso/objectivegeneral",
        element: <ObjectiveGeneral />,
      },
      {
        path: "/documentation/programs/sso/extends",
        element: <ProgramsExtends />,
      },
      {
        path: "/documentation/registers/generals/accidents",
        element: <RegistersAccidents />,
      },
      {
        path: "/documentation/registers/generals/accidents/edit",
        element: <RegistersAccidentsEdit />,
      },
      {
        path: "/documentation/registers/mypes/accidentsoccupationalsincidents",
        element: <RegistersMyAccidentsOccupationalsIncidents />,
      },
      {
        path: "/documentation/registers/mypes/accidentsoccupationalsincidents/edit",
        element: <RegistersMyAccidentsOccupationalsIncidentsEdit />,
      },
      {
        path: "/documentation/registers/mypes/tracking/edit",
        element: <RegistersMypeTrackingEdit />,
      },
      {
        path: "/documentation/registers/mypes/tracking",
        element: <RegistersMypeTracking />,
      },
      {
        path: "/documentation/registers/mypes/evaluationSSO",
        element: <EvaluationSSO />,
      },
      {
        path: "/documentation/registers/mypes/evaluationSSO/edit",
        element: <EvaluationSSOEdit />,
      },
      
      {
        path: "/documentation/registers/generals/incidentsdangerousincidents",
        element: <RegistersIncidentsDangerousIncidents />,
      },
      {
        path: "/documentation/registers/generals/incidentsdangerousincidents/edit",
        element: <RegistersIncidentsDangerousIncidentsEdit />,
      },
      {
        path: "/documentation/registers/generals/monitoring",
        element: <RegistersMonitoring />,
      },
      {
        path: "/documentation/registers/generals/monitoring/edit",
        element: <RegistersMonitoringEdit />,
      },
      // {
      //   path: "documentation/registers/generals/inspections",
      //   element: <RegistersInspections/>,
      // },
      // {
      //   path: "documentation/registers/generals/inspections/edit",
      //   element: <RegistersInspectionsEdit/>
      // },
      // {
      //   path: "documentation/registers/generals/statistics",
      //   element: <RegistersStatistics/>
      // },
      // {
      //   path: "documentations/registers/generals/statistics/edit",
      //   element: <RegistersStatisticsEdit/>
      // },
      {
        path: "/documentation/registers/generals/securityemergencyequipment",
        element: <RegistersSecurityEmergencyEquipment />,
      },
      {
        path: "/documentation/registers/generals/securityemergencyequipment/edit",
        element: <RegistersSecurityEmergencyEquipmentEdit />,
      },
      {
        path: "/documentation/registers/generals/inductiontrainingcoaching",
        element: <RegistersInductionTrainingCoaching />,
      },
      {
        path: "/documentation/registers/generals/inductiontrainingcoaching/edit",
        element: <RegistersInductionTrainingCoachingEdit />,
      },
      {
        path: "/documentation/registers/generals/audits",
        element: <RegistersAudits />,
      },
      {
        path: "/documentation/registers/generals/audits/edit",
        element: <RegistersAuditsEdit />,
      },
      {
        path: "/documentation/registers/generals/inspections",
        element: <RegistersInspections />,
      },
      {
        path: "/documentation/registers/generals/inspections/edit",
        element: <RegistersInspectionsEdit />,
      },
      {
        path: "/documentation/registers/generals/competencycertificate",
        element: <RegistersCompetencyCertificate />,
      },
      {
        path: "/documentation/registers/generals/competencycertificate/edit",
        element: <RegistersCompetencyCertificateEdit />,
      },
      {
        path: "/documentation/registers/generals/statisticaldata/dataStatisticalRecords",
        element: <RegistersStatisticalData />,
      },
      {
        path: "/documentation/registers/generals/statisticaldata/dataStatisticalRecords/edit",
        element: <RegistersStatisticalDataEdit />,
      },
      {
        path: "/documentation/registers/generals/statisticaldata/occupationalHealthAndSafetyStatistics",
        element: <RegisterHealthAndSafetyStatistics />,
      },
      {
        path: "/documentation/registers/generals/statisticaldata/occupationalHealthAndSafetyStatistics/edit",
        element: <RegisterHealthAndSafetyStatisticsEdit />,
      },
      {
        path: "/committee/proceedingsBook/addProceedingsBook",
        element: <AddProceedingsBook />
      },
      {
        path: "/committee/proceedingsBook/addProceedingsBook/proceeedingsMeeting",
        element: <ProceedingsMeeting/>
      },
      {
        path: "/committee/proceedingsBook/addProceedingsBook/proceeedingsMeeting/informationTheme",
        element: <InformationTheme/>
      },
      {
        path: "/committee/proceedingsBook/addProceedingsBook/proceeedingsMeeting/informationTheme/viewInformationTheme",
        element: <ViewInformationTheme/>
      },
      {
        path: "/editProceedingsMeeting",
        element: <EditProceedingsMeeting/>
      },
      {
        path: "/viewProceedingsMeeting",
        element: <ViewProceedingsMeeting/>
      },
      {
        path:"/committee/scheduleMeetings",
        element: <ScheduleMeetings/>
      },
      {
        path:"/editScheduleMeeting",
        element: <EditScheduleMeetings/>
      },
      {
        path:"/viewScheduleMeeting",
        element: <ViewScheduleMeetings/>
      },
      {
        path: "/committee/proceedingsBook/recommendationBook",
        element: <RecommendationsBook />,
      },
       {
        path: "/committee/electionprocess",
        element: <ElectionProcess />,
      },
      {
        path: "/committee/electionprocess/process",
        element: <ElectionProcessProcess />,
      },
      {
        path: "/committee/process",
        element: <Process />,
      },

      {
        path: "/committee/processstatus",
        element: <ProcessStatus />,
      },
      {
        path: "/committee/processstatustwo",
        element: <ProcessStatusTwo />,
      },
      {
        path: "/committee/schedulecalendar",
        element: <ScheduleCalendar />,
      },
      {
        path: "/committee/schedulecalendartwo",
        element: <ScheduleCalendarTwo />,
      },
      {
        path: "/committee/schedulecalendarthree",
        element: <ScheduleCalendarThree />,
      },
      {
        path: "/committee/presentCommittee/currentCommittee/",
        element: <CurrentCommittee />,
      },
      {
        path:"/committee/presentCommittee/internalRegulationsCommittee/",
        element: <InternalRegulationsCommittee/>
      },
      {
        path:"/committee/presentCommittee/internalRegulationsCommittee/templateMenu",
        element: <TemplateMenuRegulations/>
      },
      {
        path:"/committee/presentCommittee/internalRegulationsCommittee/templateMenu/template",
        element: <TemplateRegulations/>
      },
      {
        path: "/baseline/startprocess/free",
        element: <BaselineStartProcessFree />,
      },
      {
        path: "/baseline/startprocess",
        element: <BaselineStartProcess />,
      },
      {
        path: "/baseline/diagnosis",
        element: <BaselineDiagnosis />,
      },
      {
        path: "baseline/process",
        element: <BaselineProcess />,
      },
      {
        path: "baseline/answers",
        element: <BaselineAnswers />,
      },
      {
        path: "baseline/answerstwo",
        element: <BaselineAnswersTwo />,
      },
      {
        path: "/baseline/results",
        element: <BaselineResults />,
      },
      {
        path: "/baseline/baselineTools",
        element: <BaselineTools />,
      },
      {
        path: "/baseline/show/results",
        element: <BaselineShowResults />,
      },
      {
        path: "baseline/show/improvementactions",
        element: <BaselineImprovementActions />,
      },
      {
        path: "/dashboard",
        element: <HomeView />,
      },
      {
        path: "/ats",
        element: <IndexAts />,
      },
      {
        path: "/ats/edit",
        element: <EditAts />,
      },
      {
        path: "/ats/model/edit",
        element: <ModelEditAts />,
      },

      {
        path: "/editAts",
        element: <EditAts />,
      },
      {
        path: "/documentation/registers/generals/occupationaldiseases",
        element: <RegistersOccupationalDiseases />,
      },
      {
        path: "/documentation/registers/generals/occupationaldiseases/edit",
        element: <RegistersOccupationalDiseasesEdit />,
      },

      {
        path: "/documentation/documents/procedures/petar",
        element: <MainPetar />,
      },
      {
        path: "editPetar",
        element: <EditPetar />,
      },
      {
        path: "/documentation/documents/procedures/petar/editModel",
        element: <EditModel />,
      },
      {
        path: "/mailbox/consults",
        element: <MailboxConsults />,
      },
      {
        path: "/mailbox/reports",
        element: <MailboxReports />,
      },
      {
        path: "/mailbox/congratulations",
        element: <MailboxCongratulations />,
      },
      {
        path: "/mailbox/notifications",
        element: <MailboxNotifications />,
      },
      {
        path: "settings/users",
        element: <SettingsUsers />,
      },
      {
        path: "/settings/users/create",
        element: <SettingsUsersCreate />,
      },
      {
        path: "/settings/users/edit",
        element: <SettingsUsersEdit />,
      },
      {
        path: "/settings/company/generalinformation",
        element: <SettingsCompanyGeneralInformation />,
      },
      {
        path: "/settings/company/organization",
        element: <SettingsCompanyOrganization />,
      },
      {
        path: "/settings/company/certifications",
        element: <SettingsCompanyCertifications />,
      },
      {
        path: "/settings/campus",
        element: <SettingsCampus />,
      },
      {
        path: "/settings/password",
        element: <SettingsPassword />,
      },
      {
        path: "/company",
        element: <Company />,
      },
      {
        path: "/company/headoffices",
        element: <Headoffices />,
      },
      {
        path: "/company/organizationchart",
        element: <Organizationchart />,
      },
      {
        path: "/admin/epps",
        element: <IndexEpps />,
      },
      {
        path: "/admin/epps/categories",
        element: <IndexEppsCategories />,
      },
      {
        path: "/admin/baseline/indicators",
        element: <IndexBaseLineIndicators />,
      },
      {
        path: "/admin/baseline/lineaments",
        element: <IndexBaseLineLineaments />,
      },
      //Areas
      {
        path: "/settings/areas",
        element: <AreasIndex />,
      },
      //Positions
      {
        path: "/settings/positions",
        element: <OccupationalPositionsIndex />,
      },
      // {
      //   path: "/activities/task",
      //   element: <ActivitiesTask />,
      // },
      {
        path: "/activities/task/baseline/improvementactions",
        element: <ActivitiesBaselineImpActions />,
      },
      {
        path: "/activities/task/taksWorkers",
        element: <WorkersTasks />,
      },
      {
        path: "/activities/task/taksWorkers/taskOccurrences",
        element: <TaskOccurrences />,
      },
      {
        path: "/activities/task/mytasks",
        element: <MyTasks />,
      },
      
      
      // trainings
      {
        path: "/trainings/subjects",
        element: <TrainingsSubjectIndex />,
      },
      {
        path: "/trainings/all",
        element: <TrainingsAllIndex />,
      },
      {
        path: "/trainings/all/edit",
        element: <TrainingsAllEdit />,
      },
      {
        path: "/trainings/all/workers",
        element: <TrainingsAllWorkers />,
      },
      {
        path: "/trainings/all/evidences",
        element: <TrainingsAllEvidences />,
      },
      {
        path: "/trainings/all/materials",
        element: <TrainingsAllMaterials />,
      },
      {
        path: "/trainings/all/exams",
        element: <TrainingsAllExams />,
      },
      {
        path: "/trainings/all/exams/questions",
        element: <TrainingsAllExamsQuestions />,
      },
      {
        path: "/trainings/personal",
        element: <TrainingsPersonalIndex />,
      },
      {
        path: "/trainings/personal/edit",
        element: <TrainingsPersonalEdit />,
      },
      {
        path: "/trainings/personal/workers",
        element: <TrainingsPersonalWorkers />,
      },
      {
        path: "/trainings/personal/evidences",
        element: <TrainingsPersonalEvidences />,
      },
      {
        path: "/trainings/personal/materials",
        element: <TrainingsPersonalMaterials />,
      },
      {
        path: "/trainings/personal/exams",
        element: <TrainingsPersonalExams />,
      },
      {
        path: "/trainings/personal/exams/questions",
        element: <TrainingsPersonalExamsQuestions />,
      },

      //reuniones para pequeñas empresas
      {
        path:"/proceedingBook/scheduleMeetings",
        element: <ScheduleMeetingSmallCompany/>
      },
      {
        path:"/proceedingBook/scheduleMeetings/editScheduledMeeting",
        element: <EditScheduledMeetingsSmallCompany/>
      },

      //actas para pequeñas empresas /minutes-books/proceedingsBook
      {
        path: "/proceedingBook/proceedingBook",
        element: <ProceedingBook />,
      },
      {
        path: "/proceedingBook/proceeding",
        element: <Proceeding />,
      },
      {
        path: "/proceedingBook/editProceeding",
        element: <EditProceedingSmallCompany />,
      },
      
      {
        path: "/proceedingBook/editProceeding/informationTheme",
        element: <InformationThemeProceedingSmallCompany />,
      },

      //SuggestionsMailbox
      {
        path: "/suggestionsMailbox",
        element: <SuggestionsMailbox />,
      },

      {
        path: "/documentation/registers/generals/statisticalReports",
        element: <StatisticalReport />,
      },

    ]
  },
  {
    path: "/404",
    element: <h1>No se encontró</h1>,
  },
  {
    path: "/500",
    element: <h1>Error interno del servidor</h1>,
  },
  {
    path: "/403",
    element: <h1>Prohibidos</h1>,
  },
  //errors
  {
    path: "*",
    element: (
      <ErrorViews
        urlImg={"/assets/images/errors/imgNotFound404.png"}
        textError={"ERROR 404"}
        msgError={"Pagina no encontrada"}
      />
    ),
  },
  {
    path: "errorPermissions",
    element: (
      <ErrorViews
        urlImg={"/assets/images/errors/imgNotFound403.png"}
        textError={"ERROR 403"}
        msgError={"Error de roles, de permisos o falta de login."}
      />
    ),
  },
  {
    path: "internalError",
    element: (
      <ErrorViews
        urlImg={"/assets/images/errors/imgNotFound503.png"}
        textError={"ERROR 503"}
        msgError={"Error interno del sistema ya sea servidor o usuario."}
      />
    ),
  },
];

export default routes;