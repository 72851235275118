import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { Stack, styled } from "@mui/system";
import {
  Add,
  ArrowBackIosNew,
  ArrowForwardIos,
  DriveFolderUpload,
} from "@mui/icons-material";
import SubtitleText from "../../../../components/text/subtitleText";
import MyPetar from "../../../../api/MyPetar";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import BannerControla from "../../../../components/cards/bannerControla";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import ListModels from "./components/list-models";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import { useAppContext } from "../../../../context/AppContext";
import ListActives from "./components/list-actives";
import ListArchived from "./components/list-archived";
import TableControla from "../../../../components/tables/table-controla";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../../../components/buttons/returnBackControla";


const purple = "#8B8BD8";

function Index() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );


  const handleGetPetar = async (inputYear = undefined) => {
    handleOpenLoading();

    if (inputYear != undefined) {
      handleOpenLoading();
    }
    const response = await MyPetar.petar({
      year: inputYear == undefined ? year : inputYear,
    });


    if (response.success === false) {
      handleOpenAlert("Ocurrio un error al listar los Petar", "error");
    } else {
      const petar_model = response.petar_model;
      const petar_no_archived = response.petar_no_archived;
      const petar_archived = response.petar_archived;
      setRowsModels(petar_model);
      setRowsActives(petar_no_archived);
      setRowsArchives(petar_archived);
      if (inputYear != undefined) {
        setDocumentType(1);
        handleOpenAlert(
          "Listando Petar activos del año " + inputYear,
          "success"
        );
      }
      setOpenLoading(false);
    }
    return response;
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handlePostCreatePetar = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    if (documentUrl !== null) {
      const response = await MyPetar.createPetar({
        name,
        code,
        area,
        place,
        start_time: hourStart,
        end_time: hourFinish,
        model: 0,
        document_url: documentUrl,
      });
      if (response.success === true) {
        navigate("/documentation/documents/procedures/petar?idTabs=1");
        await handleGetPetar();
      }
    } else {
      const response = await MyPetar.createPetar({
        name,
        code,
        area,
        place,
        start_time: hourStart,
        end_time: hourFinish,
        model: 0,
        document_url: documentUrl,
      });
      if (response.success === true) {
        handleGetPetar();
        navigate("/editPetar?id_petar=" + response.id_petar);
      }
    }
    handleCloseModalUpload();
    setOpenLoading(false);

  };

  const handlePutUpdatePetar = async (idPetar) => {
    const response = await MyPetar.updatePetar({
      id_petar: idPetar,
      name,
      code,
      document_url: documentUrl,
    });
    handleGetPetar();
    setOpenModalUploadUpdate(false);
  };

  const handleDeletePetar = async () => {
    handleOpenLoading();

    const response = await MyPetar.deletePetar({ idPetar });
    if (response.success) {
      await handleGetPetar();
      handleOpenAlert("Se eliminó el registro con éxito", "success");
    }
  };

  const handlePostCreateModel = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPetar.createPetar({
      name,
      code,
      model: 1,
    });
    if (response.success) {
      navigate("/documentation/documents/procedures/petar/editModel?id_petar=" + response.id_petar);

    } else {
      handleOpenAlert("Ocurrio un error al crear el modelo PETAR", "error");
    }
  };

  const sendToWorkers = async (idPetar) => {
    try {
      const response = await MyPetar.sendToWorkers({ id_petar: idPetar });
      if (response.success) {
        handleOpenAlert(response.msg);
        handleGetPetar();
      } else {
        handleOpenAlert(response.msg, "error");
      }
    } catch (e) {
      handleOpenAlert(e);
    }
  };

  const publishPetar = async (idPetar) => {

    handleOpenLoading();
    try {
      const response = await MyPetar.publishPetar({ id_petar: idPetar });

      if (response.success) {
        handleGetPetar();
        handleOpenAlert(response.msg, "success");
      } else {
        handleOpenAlert(response.msg, "error");
      }
    } catch (e) {
      handleOpenAlert(e, 'error');
    }
  };

  const toArchive = async (idPetar) => {
    const response = await MyPetar.archivePetar({ id_petar: idPetar });
    handleGetPetar();
  };

  const toUnarchive = async (idPetar) => {
    const response = await MyPetar.unarchivePetar({ id_petar: idPetar });

    handleGetPetar();
  };

  //traer id usuario logeado
  const user = MyConfigurationApi.userData();
  const idUserLogged = user.id_users;

  useEffect(() => {
    handleGetPetar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const handleOpenModalCreate = () => setOpenModalCreate(true);
  const handleCloseModalCreate = () => setOpenModalCreate(false);

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const handleOpenAlertModal = () => setOpenAlertModal(true);
  const handleCloseAlertModal = () => setOpenAlertModal(false);

  const [toBeDeleted, setToBeDeleted] = useState();

  const [rowsModels, setRowsModels] = useState([]);
  const [rowsActives, setRowsActives] = useState([]);
  const [rowsArchives, setRowsArchives] = useState([]);

  const [idPetar, setIdPetar] = useState(null);
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [area, setArea] = useState(null);
  const [place, setPlace] = useState(null);
  const [hourStart, setHourStart] = useState(null);
  const [hourFinish, setHourFinish] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const currentTime = new Date();
  const [year, setYear] = useState(currentTime.getFullYear());

  const [documentType, setDocumentType] = useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    handleGetPetar(year + 1);
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    handleGetPetar(year - 1);
  };

  const [openModalUpload, setOpenModalUpload] = useState(false);

  const handleOpenModalUpload = () => {
    setName(null);
    setCode(null);
    setArea(null);
    setPlace(null);
    setHourStart(null);
    setHourFinish(null);
    setDocumentUrl(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => setOpenModalUpload(false);

  const [openModalUploadUpdate, setOpenModalUploadUpdate] = useState(false);

  const handleOpenModalUploadUpdate = (idPetar, name, code) => {
    setIdPetar(idPetar);
    setName(name);
    setCode(code);
    setOpenModalUploadUpdate(true);
  };

  const handleCloseModalUploadUpdate = () => {
    setOpenModalUploadUpdate(false);
  };

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  /////////////////////// ALERT

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");
  // const [dataNotification, setDataNotification] = useState({ message: null,
  //   document_url: null,
  //   id_users_created: null,
  //   id_users_modified: null,
  //   id_users_sender: null,
  //   id_users_receiving: null });

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
    // dataNotification
  ) => {
    setIdPetar(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    // setDataNotification(dataNotification);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        handleDeletePetar();
        break;
      case "sendWorkers":
        sendToWorkers(idPetar);
        break;
      case "public":
        publishPetar(idPetar);
        break;
      case "archived":
        toArchive(idPetar);
        break;
      case "unarchived":
        toUnarchive(idPetar);
        break;
    }
    setOpenModalConfirm(false);
  };

  return (
    <>
      <Box sx={{ height: "100%" }}>

        <ReturnBack/>

        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />

        <DialogConfirmControla
          openModalConfirm={openModalConfirm}
          handleCloseModalConfirm={handleCloseModalConfirm}
          titleText={titleText}
          contentText={contentText}
          buttonTextConfirm={buttonTextConfirm}
          handleActionConfirm={handleActionConfirm}
        />

        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={3} columnSpacing={6}>
              <Grid item md={12}>
                <ButtonMailboxControla
                  originUrl={"/documentation/documents/procedures/petar"}
                />
              </Grid>
              <Grid item md={12}>
                {/* NO ELIMINAR - TEMPORALMENTE COMENTADO */}

                {/* <SubtitleText
                                    text={"Procedimientos"}
                                    color={grey}>
                                </SubtitleText> */}

                <BannerControla
                  image="/assets/images/banners/documents/petarBanner.png"
                  color2="#2F67BC"
                  color1="#8FBCFF"
                  text="Permiso Escrito para Trabajos de Alto Riesgo"
                />
              </Grid>
              <Grid container item direction={"row"} md={12} gap={2}>
                <Grid item md={5}>
                  <Stack spacing={2} direction="row">
                    <ButtonControla
                      roles={permissions.DOC_HG_PROC_PETAR_MOD_AGREGAR_MODELO}
                      textButton={"Agregar modelo Petar"}
                      iconButton={<Add />}
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#4db2eb"}
                      functionOnClick={() => handleOpenModalCreate()}
                    />
                    <ButtonControla
                      roles={permissions.DOC_HG_PROC_PETAR_MOD_SUBIR_ARCHIVO}
                      textButton={"Subir Petar"}
                      iconButton={<DriveFolderUpload />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#2bcda6"}
                      functionOnClick={() => handleOpenModalUpload()}
                    />
                  </Stack>
                </Grid>
                {/* COMENTADO POR EL MOMENTO - NO ELIMINAR */}
                {/* <Grid
                  item
                  md={1.8}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Button
                    //onClick={handleClickOpen}
                    variant="contained"
                    startIcon={<TableChart />}
                    sx={{
                      textTransform: "none",
                      backgroundColor: "#007bff",
                      borderRadius: 6,
                      width: "100%",
                    }}
                    disableElevation
                    onClick={handleOpenModalModel}
                  >
                    Usar Plantilla
                  </Button>
                </Grid> */}
                {/* <Grid item md={4.8}></Grid> */}
                <Grid item md={3.5}></Grid>
                <Grid
                  container
                  spacing={2}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  xl={3}
                  alignItems="center"
                  justifyContent={"right"}
                >
                  <Grid item xs={3} sm={2} md={3} lg={3} xl={3}>
                    <InputLabel
                      sx={{
                        padding: "8px 0px 8px 0px",
                        fontSize: "14px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Periodo:
                    </InputLabel>
                  </Grid>
                  <Grid item xs={3} sm={3} md={5} lg={4} xl={4}>
                    <InputBase
                      type="number"
                      value={year}
                      onChange={handleGetPetar}
                      readOnly
                      sx={{
                        width: "100%",
                        padding: "4px 10px 4px 10px",
                        backgroundColor: "white",
                        color: "#305AD9",
                        fontSize: "14px",
                        borderRadius: "8px",
                      }}
                    ></InputBase>
                  </Grid>
                  <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        color: "#305AD9",
                      }}
                      onClick={handleDecreaseYear}
                    >
                      <ArrowBackIosNew />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        color: "#305AD9",
                      }}
                      onClick={handleIncreaseYear}
                    >
                      <ArrowForwardIos />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={12}></Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                <TableControla
                  loading={openLoading}
                  tabs={[
                    {
                      label: "Modelos",
                      columnNames: ["Nombre del documento"]
                    },
                    {
                      label: "Activos",
                      columnNames: ["Nombre del documento", "Tipo", "Estado"]
                    },
                    {
                      label: "Archivados",
                      columnNames: ["Nombre del documento", "Tipo", "Estado"]
                    }
                  ]}
                >
                  <ListModels
                    rowsModels={rowsModels}
                    colorCell={purple}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                  />

                  <ListActives
                    rowsActives={rowsActives}
                    idUserLogged={idUserLogged}
                    colorCell={purple}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                    handleOpenModalUploadUpdate={handleOpenModalUploadUpdate}
                  />

                  <ListArchived
                    rowsArchives={rowsArchives}
                    colorCell={purple}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                  />

                </TableControla>

              </Grid>
            </Grid>

            <Dialog
              open={openModalCreate}
              onClose={handleCloseModalCreate}
              maxWidth="sm"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "55%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Nuevo Modelo de PETAR"
                functionOnClose={handleCloseModalCreate}
              />
              <form onSubmit={handlePostCreateModel}>
                <DialogContent>
                  <InputControlaVertical
                    text="Código"
                    inputType={"text"}
                    inputValue={setCode}
                    modalType={false}
                    required
                  />
                  <InputControlaVertical
                    text="Nombre del modelo de PETAR"
                    inputType={"text"}
                    inputValue={setName}
                    modalType={false}
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Box>
                    <ButtonControla
                      backgroundColor={"#CBCBFF"}
                      textButton={"Cancelar"}
                      backgroundColorHover={"#CBCBFF"}
                      typeButton={"button"}
                      functionOnClick={handleCloseModalCreate}
                    >
                      Cancelar
                    </ButtonControla>
                  </Box>
                  <Box>
                    <ButtonControla
                      iconButton={<Add />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Crear modelo de PETAR"}
                      typeButton={"submit"}
                    >
                      Agregar nuevo modelo
                    </ButtonControla>
                  </Box>
                </DialogActions>
              </form>
            </Dialog>
            <Dialog
              open={openModalUpload}
              onClose={handleCloseModalUpload}
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "65%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Subir documento PETAR"
                functionOnClose={handleCloseModalUpload}
              />
              <form onSubmit={handlePostCreatePetar}>
                <DialogContent>
                  <InputControlaVertical
                    text={"Título del documento"}
                    inputType={"text"}
                    inputValue={setName}
                    modalType={false}
                    required
                  />
                  <InputControlaVertical
                    text={"Código"}
                    inputType={"text"}
                    inputValue={setCode}
                    modalType={false}
                    required
                  />
                  <InputControlaVertical
                    text={"Subir Archivo"}
                    inputType={"file"}
                    inputValue={setDocumentUrl}
                    modalType={false}
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Box>
                    <ButtonControla
                      backgroundColor={"#CBCBFF"}
                      textButton={"Cancelar"}
                      backgroundColorHover={"#CBCBFF"}
                      typeButton={"button"}
                      functionOnClick={handleCloseModalUpload}
                    ></ButtonControla>
                  </Box>
                  <Box>
                    <ButtonControla
                      iconButton={<Add />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Subir archivo PETAR"}
                      typeButton={"submit"}
                    ></ButtonControla>
                  </Box>
                </DialogActions>
              </form>
            </Dialog>
            <Dialog open={openAlertModal} onClose={handleCloseAlertModal}>
              <DialogTitle>
                ¿Seguro que desea eliminar este documento?
              </DialogTitle>
              <DialogActions>
                <Button>Eliminar</Button>
                <Button onClick={handleCloseAlertModal}>Cancelar</Button>
              </DialogActions>
            </Dialog>
            {/* modal actualizar subir doc */}
            <Dialog
              open={openModalUploadUpdate}
              onClose={handleCloseModalUploadUpdate}
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "65%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitle>
                <Typography
                  id="modal-modal-title"
                  variant="h4"
                  component="h2"
                  sx={{
                    color: "#1638F2",
                    fontWeight: "bold",
                  }}
                >
                  Editar PETAR
                </Typography>
              </DialogTitle>
              <DialogContent>
                <InputControlaVertical
                  text={"Título del documento"}
                  inputType={"text"}
                  value={name}
                  inputValue={setName}
                  modalType={false}
                  required
                />
                <InputControlaVertical
                  text={"Código"}
                  inputType={"text"}
                  value={code}
                  inputValue={setCode}
                  modalType={false}
                  required
                />
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  value={documentUrl}
                  inputValue={setDocumentUrl}
                  modalType={false}
                  required
                />
              </DialogContent>
              <DialogActions>

                <Button
                  variant="contained"
                  // color="success"
                  sx={{
                    textTransform: "none",
                    color: "#6969D6",
                    backgroundColor: "#CBCBFF",
                    borderRadius: "16px",
                    marginRight: "10px",
                  }}
                  onClick={handleCloseModalUploadUpdate}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    textTransform: "none",
                    // backgroundColor: '#169073',
                    borderRadius: "16px",
                    marginRight: "10px",
                  }}
                  onClick={() => handlePutUpdatePetar(idPetar)}
                >
                  Editar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openConfirmModal}
              onClose={handleCloseConfirmModal}
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "65%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent></DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Index;
