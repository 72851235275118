// colocar lo de Workaccidents.jsx
import * as React from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  Delete,
  VisibilityOutlined,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";

import BannerControla from "../../../../components/cards/bannerControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import MyRegistersCompetencyCertificate from "../../../../api/MyRegistersCompetencyCertificate";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import { useAppContext } from "../../../../context/AppContext";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

function Index() {
  const {permissions} = useAppContext();
  const navigate = useNavigate();

  const [code, setCode] = React.useState("");

  const [rowsActives, setRowsActives] = React.useState([]);
  const [openModalUpload, setOpenModalUpload] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [idUserLoged, setIdUserLoged]  = React.useState(null);
  const rolesEdit = permissions.DOC_REGIS_CERTIF_APTITUD_EXAM_MEDICO_EDITAR;		

  /**** Message Alert */
  const [stateAlert, setStateAlert] = React.useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenModalUpload = () => {
    setCode(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };

 
  const handleGetCertificate = async () => {
    setOpenBackdrop(!openBackdrop);
    //traer datos usuario logeado
    const resp = await MyConfigurationApi.userData();
    const idUserLoged = resp.id_users;
    const rolesUserLogged = resp.roles;
    let isCheckAll = false;
    setIdUserLoged(idUserLoged);
      
    //verificar si es rol 1,3,4 
    rolesEdit?.forEach(rol => {
      if(rolesUserLogged.includes(rol)){
        isCheckAll=true;
      }
    });

    // mostrar registros de acuerdo al usuario que corresponda 
    const response = await MyRegistersCompetencyCertificate.getRegisterCertificate();
    const registerUser = response.filter((row)=> row.id_users == idUserLoged);
    
    if(isCheckAll){
      setRowsActives(response);
    }else{
      setRowsActives(registerUser);
    }

    handleCloseBackdrop();
  };

  const handleCreateCertificate = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyRegistersCompetencyCertificate.createRegisterCertificate({
        code: code,
      });

    if (response != "") {
      handleCloseBackdrop();
      handleCloseModalUpload();
  
      navigate("/documentation/registers/generals/competencycertificate/edit?idRegistersCertificate=" + response.id);
    } else {
      handleClickAlert("error", "Error al registrar documento");
      handleCloseBackdrop();
      handleCloseModalUpload();
    }
  };
  const handleDeleteCertificate = async(id) => {
    setOpenBackdrop(true);
    const response = await MyRegistersCompetencyCertificate.deleteRegisterCertificate( {idRegister: id,} );
    if(response.success == true){
      await handleGetCertificate();
      handleCloseModalDelete();
      handleClickAlert("success", "Se eliminó el certificado con éxito");
    }else{
      handleClickAlert("error","Ocurrió un error al eliminar el certificado");
    }

    handleCloseBackdrop();
  };
  /////Modal Delete

  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [rowId, setRowId] = React.useState();
  const handleOpenModalDelete = (id) => {
    setRowId(id);
    setOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  React.useEffect(() => {
    handleGetCertificate();
  }, [rolesEdit]);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <ButtonMailboxControla
              originUrl={"/documentation/registers/generals/competencycertificate"}
            />
            
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannercompetencycertificate.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Certificados de aptitud"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                roles = {permissions.DOC_REGIS_CERTIF_APTITUD_EXAM_MEDICO_AGREGAR_REGIS}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar nuevo certificado"}
                functionOnClick={handleOpenModalUpload}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid item md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Código
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Nombres y Apellidos
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Documento
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Sexo
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Tipo de examen
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Resultado
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Fechas
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Observaciones
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rowsActives.map((row) => (
                        <TableRow
                          key={row.id_registers_accidents}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "4px",
                          }}
                        >
                          <TableCell align="center" component="th" scope="row">
                            {row.code}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.first_name === "" ||
                            row.first_name === undefined ||
                            row.first_name === null
                              ? "Sin completar"
                              : row.first_name + " " + row.last_name}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.document === null || row.document === ""
                              ? "Sin completar"
                              : row.document}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.users_gender === "" || row.document === ""
                              ? "Sin completar"
                              : row.users_gender}
                          </TableCell>
                          {/* <TableCell align="center" component="th" scope="row">
                            {row.register_status == 1 ? "Creado" : "Cerrado"}
                          </TableCell> */}
                          <TableCell align="center" component="th" scope="row">
                            {row.exam_type === ""
                              ? "Sin completar"
                              : row.exam_type}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.result === "" ? "Sin completar" : row.result}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.exam_date === null
                              ? "Sin fecha"
                              : row.exam_date}{" "}
                            /
                            {row.next_exam_date === null
                              ? "Sin fecha"
                              : row.next_exam_date}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.observations === ""
                              ? "Sin completar"
                              : row.observations}
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {row.register_status === 1 && (
                                <ButtonIconControla
                                  roles = {permissions.DOC_REGIS_CERTIF_APTITUD_EXAM_MEDICO_EDITAR}
                                  icon={
                                    <BorderColorOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  isNavegationOrigin={true}
                                  directionUrl={"/documentation/registers/generals/competencycertificate/edit?idRegistersCertificate=" + row.id}
                                />
                              )}
                              
                              {row.document_url !== "" && (
                                <ButtonIconControla
                                  roles = {row.id_users === idUserLoged ? [] : permissions.DOC_REGIS_CERTIF_APTITUD_EXAM_MEDICO_VER}
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  directionUrl={row.document_url}
                                  directionTarget={"_blank"}
                                />
                              )}
                              <ButtonIconControla
                                roles = {permissions.DOC_REGIS_CERTIF_APTITUD_EXAM_MEDICO_ELIMINAR}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalDelete(row.id);
                                }}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalUpload}
        onClose={handleCloseModalUpload}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar código del certificado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateCertificate}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <InputControlaVertical
              text={"Código"}
              inputType={"text"}
              inputValue={setCode}
              modalType={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUpload}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={openModalDelete}
        onClose={handleCloseModalDelete}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
        titleText="¿Eliminar Certificado?"
        functionOnClose={()=> handleCloseModalDelete()}/>
          <DialogContent>
            <Typography sx={{
              paddingTop: "1em"
            }}>
              No se podrá recuperar el certificado una vez eliminado.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalDelete}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}

              onClick={()=>  handleDeleteCertificate(rowId)}
            >
              Eliminar
            </Button>
          </DialogActions>

      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Index;
